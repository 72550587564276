<template>
  <div class="campaign-list-table">
    <div v-if="loader && localCampaigns.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else>
      <!-- FILTERS -->
      <div class="d-flex align-items-center mb-4 default-form">
        <span class="me-4">{{ $t('filters') }}</span>
        <div class="me-3">
          <span class="fw-bold form-label me-3">{{ $t('name') }}:</span>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="filters.name"
            @keyup.enter.prevent="search"
          />
        </div>
        <div class="me-3" v-if="!advertiserId">
          <span class="fw-bold form-label me-3">{{ $t('advertisers') }}:</span><br/>
          <template v-if="advertisersLoader">
            <Loader
              :size="'small'"
              :align="'start'"
            />
          </template>
          <div class="custom-select custom-select-sm" v-else>
            <select
              v-model="filters.advertiser"
              class="form-control form-control-sm"
            >
              <option value="">{{ $t('all') }}</option>
              <option :value="advertiser.id" v-for="(advertiser, index) in advertisers" :key="index">
                {{ advertiser.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="me-3">
          <span class="fw-bold form-label me-3">{{ $t('status') }}:</span><br/>
          <div class="custom-select custom-select-sm">
            <select
              v-model="filters.status"
              class="form-control form-control-sm"
            >
              <option value="">{{ $t('all') }}</option>
              <option value="ACTIVE">{{ $t('status_type.active') }}</option>
              <option value="FINISHED">{{ $t('status_type.finished') }}</option>
              <option value="PAUSED">{{ $t('status_type.paused') }}</option>
              <option value="DRAFT">{{ $t('status_type.draft') }}</option>
              <option value="PENDING">{{ $t('status_type.pending') }}</option>
            </select>
          </div>
        </div>
        <div>
          <Button
            type="button"
            class="btn-primary btn-xs mt-3"
            @click="search"
          >
            {{ $t('search') }}
          </Button>
        </div>
      </div> <!-- END FILTERS -->
      <!-- CARD -->
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>{{ $t('advertiser') }}</th>
                  <th>{{ $t('campaign') }}</th>
                  <th>{{ $t('type') }}</th>
                  <th>{{ $t('status') }}</th>
                  <th>{{ $t('start') }}</th>
                  <th>{{ $t('end') }}</th>
                  <th>{{ $t('budget') }}</th>
                  <th>{{ $t('spending') }}</th>
                  <th>{{ $t('impressions') }}</th>
                  <th>{{ $t('clicks') }}</th>
                  <th>{{ $t('conversions') }}</th>
                  <th>{{ $t('ctr') }}</th>
                  <th>{{ $t('cpm') }}</th>
                  <th>{{ $t('cpa') }}</th>
                  <th width="100">
                    {{ $t('details') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-nowrap" v-for="(campaign, index) in localCampaigns" :key="index">
                  <td>
                    <router-link :to="{ name: 'advertisers.show', params: { id: campaign.advertiser.id } }" class="link-color3">
                      {{ campaign.advertiser.name }}
                    </router-link>
                  </td>
                  <td>
                    <router-link :to="{ name: 'campaigns.show', params: { id: campaign.id } }" class="link-color3">
                      {{ campaign.name }}
                    </router-link>
                  </td>
                  <td>{{ campaign.type | formatCampaignType }}</td>
                  <td>{{ campaign.status | formatCampaignStatus }}</td>
                  <td>
                    <span v-if="campaign.start_date">{{ campaign.start_date | formatDate }}</span>
                    <span v-else>{{ $t('not_defined') }}</span>
                  </td>
                  <td>
                    <span v-if="campaign.end_date">{{ campaign.end_date | formatDate }}</span>
                    <span v-else>{{ $t('not_defined') }}</span>
                  </td>
                  <td>{{ campaign.amount / 100 | formatMoney(campaign.advertiser.currency) }}</td>
                  <td>{{ campaign.spending / 100 | formatMoney(campaign.advertiser.currency) }}</td>
                  <td>{{ campaign.impressions | formatNumber }}</td>
                  <td>{{ campaign.clicks | formatNumber }}</td>
                  <td>{{ campaign.conversions | formatNumber }}</td>
                  <td>{{ campaign.ctr }}%</td>
                  <td>{{ campaign.cpm / 100 | formatMoney(campaign.advertiser.currency) }}</td>
                  <td>{{ campaign.cpa / 100 | formatMoney(campaign.advertiser.currency) }}</td>
                  <td>
                    <router-link :to="{ name: 'campaigns.show', params: { id: campaign.id } }" class="link-color3" v-b-tooltip.hover :title="$t('see_more')">
                      <i class="fa fa-eye"></i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mt-4">
            <Button
              class="btn-primary btn-sm"
              :loading="loader"
              @click="loadMore"
              v-if="isLoadMoreVisible"
            >
              {{ $t('load_more') }}
            </Button>
          </div>
        </div>
      </div> <!-- END CARD -->
    </template>
  </div>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import { mapState } from 'vuex'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'CampaignListTable',
  props: ['advertiserId', 'campaigns'],
  components: {
    Button,
    Loader
  },
  data () {
    return {
      filters: {
        name: '',
        status: '',
        advertiser: ''
      },
      localCampaigns: [],
      loader: false,
      page: 1,
      isLoadMoreVisible: true,
      advertisers: [],
      advertisersLoader: false
    }
  },
  created () {
    if (this.campaigns) {
      this.localCampaigns = this.campaigns
      this.isLoadMoreVisible = false
    } else {
      this.getCampaigns()
      if (!this.advertiserId) this.getAdvertisers()
    }
  },
  watch: {
    user (newValue, oldValue) {
      if (newValue) this.getAdvertisers()
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  },
  methods: {
    /**
     * Get campaigns
     */
    async getCampaigns () {
      if (!this.user) return false
      this.loader = true
      try {
        const campaigns = await CampaignsService.getCampaigns({
          advertiserId: this.advertiserId || this.filters.advertiser,
          companyId: this.advertiserId ? null : this.user.company.id,
          name: this.filters.name,
          status: this.filters.status,
          page: this.page
        })
        this.localCampaigns = [...this.localCampaigns, ...campaigns.data]
        this.isLoadMoreVisible = campaigns.meta.current_page !== campaigns.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Get Advertisers
     */
    async getAdvertisers () {
      if (this.advertiserId) return
      this.advertisersLoader = true
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          companyId: this.advertiserId ? null : this.user.company.id
        })
        this.advertisers = advertisers.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.advertisersLoader = false
    },
    /**
     * Load more campaigns
     */
    loadMore () {
      this.page++
      this.getCampaigns()
    },
    /**
     * Search
     */
    search () {
      this.page = 1
      this.localCampaigns = []
      this.getCampaigns()
    }
  }
}
</script>
